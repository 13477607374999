import React from "react"
import style from "./equipmentList.mod.scss"
import PropTypes from "prop-types"

const EquipmentList = ({ items, modifier, size = "large" }) => {
  const classes = [style.equipmentList]
  if (size === "small") {
    classes.push(`${style.equipmentList}--${size}`)
  }
  modifier && classes.push(`${style.equipmentList}--${modifier}`)
  return (
    <div className={classes.join(" ")}>
      {items.map(item => {
        return <img key={item.id} src={item.icon.url} alt={item.name} />
      })}
    </div>
  )
}

EquipmentList.propTypes = {
  items: PropTypes.array.isRequired,
  modifier: PropTypes.string,
  size: PropTypes.string
}

export default EquipmentList
