import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { challengeCtaConfig } from "@helpers/listHelper"
import CardsContainer from "@global/cards/CardsContainer"
import Banner from "@global/layout/Banner"
import Cta from "@global/cards/Cta"
import Block from "@global/layout/Block"
import Registered from "@svgs/registered.svg"
import ModuleArea from "@helpers/moduleArea"
import { useChallenge } from "../context/ChallengeContext/ChallengeState"
import { ProtectedRoute } from "../components/ProtectedRoute"
import { useAuth0 } from "@utils/auth"
import Spinner from "@global/layout/Spinner"

const ChallengeDetail = ({
  data: { group, challenges },
  pageContext: { title, parent }
}) => {
  const {
    autoPilotLists,
    getUserContactLists,
    hasBeenChecked,
    loadingChallenge
  } = useChallenge()
  const { loading, user } = useAuth0()

  useEffect(() => {
    if (user && !hasBeenChecked) {
      getUserContactLists(user?.email)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (loading || !user || loadingChallenge) {
    return <Spinner />
  }

  const checkIfUserIsRegistered = listId => {
    return autoPilotLists.includes(listId)
  }

  return (
    <ProtectedRoute>
      <Layout title={title} parent={parent}>
        <HelmetDatoCms defer={false} seo={group.seoMetaTags} />
        <Banner image={group.banner} hiddenSmall={true} />
        <Block padding="top" gutters={true} maxWidth="inner">
          <h1>{title}</h1>
        </Block>
        {group.content && <ModuleArea modules={group.content} />}
        <Block padding="double-top" gutters={true} maxWidth="list-inner">
          {challenges.nodes.length > 0 ? (
            <CardsContainer>
              {challenges.nodes.map(challenge => (
                <Cta
                  {...challengeCtaConfig(challenge)}
                  key={challenge.id}
                  overlay={
                    checkIfUserIsRegistered(challenge.listId) && <Registered />
                  }
                />
              ))}
            </CardsContainer>
          ) : (
            <Block gutters={true}>
              <p>No Challenges</p>
            </Block>
          )}
        </Block>
      </Layout>
    </ProtectedRoute>
  )
}
export const query = graphql`
  query challengeGroupById($id: String, $slug: String, $language: String) {
    group: datoCmsChallengeGroup(id: { eq: $id }) {
      id
      slug
      path
      title
      startDate
      date: startDate(formatString: "dddd do MMM")
      registrationsClose
      duration
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      anytimeChallenge
      banner {
        gatsbyImageData(imgixParams: { w: "1280", h: "640", fit: "crop" })
      }
      ...ChallengeGroupModuleArea
    }
    challenges: allDatoCmsChallenge(
      filter: { locale: { eq: $language }, group: { slug: { eq: $slug } } }
    ) {
      nodes {
        ...ChallengeCta
      }
    }
  }
`
export default ChallengeDetail
